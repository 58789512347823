export const COTS_TEST_DOMAINS = [
  "dev.test.cots.teliacompany.net",
  "sit.test.cots.teliacompany.net",
  "at.test.cots.teliacompany.net",
  "mite.test.cots.teliacompany.net",
];

export const COTS_PROD_DOMAINS = ["cots.teliacompany.net", "beta.cots.teliacompany.net"];

export const TELIA_SE_TEST_DOMAINS = [
  "dev.test.telia.se",
  "sit.test.telia.se",
  "at.test.telia.se",
  "mite.test.telia.se",
];

export const TELIA_SE_PROD_DOMAINS = ["www.telia.se"];

export const SPECIAL_DOMAINS = ["mybusiness-test.dc.teliacompany.net"];
