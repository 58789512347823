import { corpCustomerGroupSearch } from "@telia/b2x-rest-client";
import { cotsDomains, loggedOutSfSessionValue, REDIRECT_URI_KEY } from "./constants";
import { B2B_START_PAGE_URL } from "@telia/b2b-utils";
import { logoutCurrentUser } from "@telia/b2b-corp-login";

export const goToLoginPage = (): void => {
  window.location.href = B2B_START_PAGE_URL;
};

export const alreadyOnLoginPage = (): boolean => {
  return window.location.pathname.startsWith(B2B_START_PAGE_URL);
};

export const alreadyOnRedirectUrl = (redirectUrl: string): boolean => {
  const logoutRedirectUrl = new URL(redirectUrl);
  const redirectUri = logoutRedirectUrl.searchParams.get(REDIRECT_URI_KEY);
  if (!redirectUri) {
    throw new Error("No redirect URI present");
  }

  const parsedRedirectUri = new URL(redirectUri);
  return window.location.pathname.startsWith(parsedRedirectUri.pathname);
};

export const logoutUser = async (): Promise<void> => {
  const sfSessionMatch = [...document.cookie.matchAll(/SFSession=([^;]*)/gm)]?.flat();
  const sfSession: string = sfSessionMatch?.[1];
  // already logged out
  if (sfSession === loggedOutSfSessionValue) {
    if (!alreadyOnLoginPage()) {
      goToLoginPage();
    }
    return;
  }
  // valid sf session present - logout
  const redirectUrl = await logoutCurrentUser();
  // check if it's possible to redirect
  try {
    if (redirectUrl && !alreadyOnRedirectUrl(redirectUrl)) {
      window.location.href = redirectUrl;
    }
  } catch (error: unknown) {
    // No redirect URI in response, fallback (if not already here)
    if (!alreadyOnLoginPage()) {
      goToLoginPage();
    }
  }
};

export const getCurrentTime = (): number => {
  return new Date().getTime();
};

export const isCots = (): boolean => cotsDomains.includes(window.location.host);

export const backendReachable = async (): Promise<boolean> => {
  try {
    await corpCustomerGroupSearch.PublicSearchControllerService.searchUsingGet1(
      "teliasaurus/b2b-idle__cots_ping"
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const sendSessionExpiredAlert = (text: string): boolean => {
  alert(text);

  return true;
};
