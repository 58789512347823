import { createStore, Store } from "@telia/b2x-state";
import { idleTimeoutLimit, eventTriggers } from "./constants";
import { IdleStatus } from "./interfaces";
import {
  getCurrentTime,
  goToLoginPage,
  isCots,
  logoutUser,
  sendSessionExpiredAlert,
  backendReachable,
  alreadyOnLoginPage,
} from "./util";
import { t } from "./locale";

let setTimeoutId: number | null = null;
let loggedOutInThisTab = false;
let callbackOptions: Callbacks;

interface Callbacks {
  goToLoginPageCallback?: (() => void) | (() => Promise<void>);
  logoutCallback?: (() => void) | (() => Promise<void>);
  shouldSessionBeExtended?: (() => boolean) | (() => Promise<boolean>);
}

export let idleStore: Store<IdleStatus, null>;

export const startIdleTimer = async (): Promise<void> => {
  idleStore.setState({
    current: getCurrentTime(),
    hasLoggedOut: false,
  });

  idleStore.subscribe((state) => {
    if (!loggedOutInThisTab && state?.hasLoggedOut) {
      goToLoginPage();
    }
  });

  eventTriggers.forEach((eventName) => document.body.addEventListener(eventName, resetTimeout));

  resetTimeout();
};

const resetTimeout = (): void => {
  if (setTimeoutId) {
    window.clearTimeout(setTimeoutId);
    setTimeoutId = null;
  }

  const idleState = idleStore.getState();

  if (idleState?.hasLoggedOut) {
    return;
  }

  setTimeoutId = window.setTimeout(handleTimeout, idleTimeoutLimit);

  idleStore.setState({
    ...idleState,
    current: getCurrentTime(),
  });
};

const handleTimeout = async (): Promise<void> => {
  const idleState = idleStore.getState();
  const current = idleState ? idleState.current : 0;
  const now = getCurrentTime();

  if (idleState?.hasLoggedOut && !alreadyOnLoginPage()) {
    if (!callbackOptions.goToLoginPageCallback) {
      throw new Error("goToLoginPageCallback was not defined");
    }

    callbackOptions.goToLoginPageCallback();
  } else if (now - current >= idleTimeoutLimit) {
    const treatAsLoggedInForAnotherPass =
      callbackOptions.shouldSessionBeExtended &&
      (await callbackOptions.shouldSessionBeExtended()) === true;

    if (treatAsLoggedInForAnotherPass) {
      resetTimeout();
    } else {
      loggedOutInThisTab = true;

      idleStore.setState({
        current: getCurrentTime(),
        hasLoggedOut: true,
      });

      if (!callbackOptions.logoutCallback) {
        throw new Error("logoutCallback not defined");
      }
      callbackOptions.logoutCallback();
    }
  } else {
    resetTimeout();
  }
};

// single-spa exports
export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  loggedOutInThisTab = false;
  idleStore = createStore("idle", {
    persistent: true,
    initialState: {
      current: 0,
      hasLoggedOut: false,
    } as IdleStatus,
  });

  if (isCots()) {
    let alertPerformed = false;

    callbackOptions = {
      goToLoginPageCallback: () => {
        if (!alertPerformed) {
          alertPerformed = sendSessionExpiredAlert(t("COTS_SESSION_EXPIRED"));
        }
      },
      logoutCallback: () => {
        if (!alertPerformed) {
          alertPerformed = sendSessionExpiredAlert(t("COTS_SESSION_EXPIRED"));
        }
      },
      shouldSessionBeExtended: async () => {
        const loggedIn = await backendReachable();

        return loggedIn;
      },
    };
  } else {
    callbackOptions = { goToLoginPageCallback: goToLoginPage, logoutCallback: logoutUser };
  }

  await startIdleTimer();
}

export async function unmount(): Promise<void> {
  if (setTimeoutId) {
    clearTimeout(setTimeoutId);
  }

  eventTriggers.forEach((eventName) => document.body.removeEventListener(eventName, resetTimeout));
}
