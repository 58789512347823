import { COTS_TEST_DOMAINS, COTS_PROD_DOMAINS } from "@telia/b2x-telia-se-domain-names";

export const idleTimeoutLimit = 3600 * 1000;

export const eventTriggers: string[] = [
  "DOMMouseScroll",
  "mousedown",
  "mousemove",
  "mousewheel",
  "MSPointerDown",
  "MSPointerMove",
  "keydown",
  "touchmove",
  "touchstart",
  "wheel",
];

export const REDIRECT_URI_KEY = "post_logout_redirect_uri";
export const loggedOutSfSessionValue = "deleted";

export const cotsDomains = [...COTS_TEST_DOMAINS, ...COTS_PROD_DOMAINS];
